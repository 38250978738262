<template>
  <DisplayView 
    :itemId="itemId" 
    :isDisplay="isDisplay"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"></DisplayView>
</template>

<script>
import DisplayView from '../../../../inspection/list/repair/RepairView.vue';

export default {
  name: 'compliance-list-inspection-details-display-row',
  components: {
    DisplayView
  },
  props: {
    itemId: { type: String, default: "" },
    isDisplay: { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
